<template>
  <div>
    <b-form-input
      maxlength="20"
      type="number"
      autocomplete="off"
      :value="providerGuideUpdate"
      @input="debounceChangeProviderGuideNumber"
      :readonly="readonly"
      :state="isValid()"
    />
    <b-form-invalid-feedback :state="isValid()">
      <div v-if="validated && !providerGuideUpdate">
        Campo obrigatório
      </div>
      <div v-else-if="guideNumberExistsInBatch">
        Insira um Nº de guia que não esteja em lote
      </div>
      <div v-else-if="providerGuideUpdate < 1">
        Insira um número maior que zero
      </div>
      <div v-else>{{ state }}</div>
    </b-form-invalid-feedback>
  </div>
</template>
<script>
import { debounce } from 'lodash'
import { mapState } from 'vuex'
export default {
  name: 'ProviderGuideInput',
  props: {
    providerGuide: {
      type: [String, Number]
    },
    type: {
      type: String,
      validator(value) {
        return [
          'consultation',
          'sp_sadt',
          'hospitalization_request',
          'hospitalization_summary',
          'fee'
        ].includes(value)
      }
    },
    validated: {
      type: Boolean,
      default: false
    },
    guideNumberExistsInBatch: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    validateProviderGuide: {
      type: Function,
      default: () => {}
    },
    state: {
      type: String
    }
  },
  computed: {
    ...mapState('manageGuides', ['requiredFields']),
    providerGuideUpdate: {
      get() {
        return this.providerGuide
      },
      set(value) {
        this.$emit('update:provider-guide', value)
      }
    }
  },
  data() {
    return {
      debounceChangeProviderGuideNumber: debounce(this.changeProviderGuideNumber, 800),
    }
  },
  methods: {
    isValid() {
      if (!this.validated || !this.requiredFields.provider_guide) return null
      return this.providerGuideUpdate > 0 && (!!this.providerGuideUpdate || !!this.guideNumberExistsInBatch)
    },
    changeProviderGuideNumber(providerNumber) {
      this.validated = false
      this.validateProviderGuide(providerNumber)
      this.providerGuideUpdate = providerNumber
    }
  }
}
</script>
